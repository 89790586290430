import FqApi from '@/services/fq-api'
//import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseOutstandingPartFilter(filter)
    const url = `outstandingparts/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseOutstandingPartFilter(filter) {
    const entityProto = require('../../assets/proto/outstandingpart/OutstandingPartFilter.proto')
    const filterMessage = entityProto.OutstandingPartFilter.OutstandingPartFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}
