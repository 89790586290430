import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Quote No.', 'quoteNo', true, 'desc', 'left'),
  new TableColumn('Vendor Name', 'vendorName', true, 'desc', 'left'),
  new TableColumn('Item No.', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'description', true, 'desc', 'left'),
  new TableColumn('Item Type', 'itemType', true, 'asc', 'left'),
  new TableColumn('Value', 'actualAmount', true, 'asc', 'centered'),
  new TableColumn('', 'View', false, '', 'centered')
]

export { Columns }
