<template>
  <div v-if="$userInfo">
    <app-detail-header
      :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <outstanding-part-list-side-menu
          v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr
                v-for="(entity, index) in entitySummaries"
                :key="entity.purchaseOrderItemId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>{{ entity.quoteNo }}</td>
                <td>{{ entity.vendorName }}</td>
                <td>{{ entity.itemNo }}</td>
                <td>{{ entity.description }}</td>
                <td>{{ entity.itemType }}</td>
                <td class="has-text-right">{{ entity.actualAmount | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    data-tooltip="Click to view"
                    @click="gotoV1(entity.purchaseOrderId)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-file-document mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import OutstandingPartListSideMenu from './OutstandingPartListSideMenu'
import OutstandingPartService from './OutstandingPartService'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import StoreMixin from './storeMixin'
import { Emailer } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'OutstandingPartListView',
  components: {
    AppDetailHeader,
    [OutstandingPartListSideMenu.name]: OutstandingPartListSideMenu,
    BulmaTable
  },
  mixins: [StoreMixin, NumberFiltersMixin],
  data() {
    return {
      entitySummaries: [],
      totalRows: 0,
      filter: {
        quoteNo: '',
        vendor: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        //sortColumn: '',
        //sortOrder: '',
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.getEntitySummaries()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.quoteNo = ''
      this.filter.vendor = ''
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await OutstandingPartService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    gotoV1(purchaseOrderId) {
      window.open(`${process.env.VUE_APP_ROOT_URI}/modules/PurchaseOrderView/PurchaseOrder.aspx?OpID=${purchaseOrderId}`, '_self')
    },
    print() {
      let sortBy = this.filter.sortColumn && this.filter.sortOrder ? `${this.filter.sortColumn.toUpperCase()} ${this.filter.sortOrder.toUpperCase()}` : ''
      let params = {
        REPORTTITLE: 'OUTSTANDING PARTS',
        COMPANYNAME: this.$userInfo.companyName,
        CompanyID: this.$userInfo.companyId,
        QuoteNo: this.filter.quoteNo === '' ? 'NULL' : this.filter.quoteNo,
        SortBy: sortBy === '' ? 'NULL' : sortBy,
        VendorName: this.filter.vendor === '' ? 'NULL' : this.filter.vendor.toUpperCase()
      }

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Outstanding Parts List'
      emailer.reportName = 'OutstandingPartsList'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId:  this.$guid.newGuid(), emailerId: emailer.id }
      })
    }
  }
}
</script>
